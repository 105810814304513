<template>
  <div class="row">
    <div id="recaptcha-container"></div>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Profile'">
        <template v-slot:preview>
          <div class="card card-custom no-box-shadow">
            <div class="card-body resturant-setting-vue p-0">
              <div
                class="d-flex flex-wrap justify-content-start restaurant-setting-menu"
              >
                <div v-for="item in menu" :key="item.id">
                  <button
                    :class="item.class"
                    @click="updateClass(item.id, item.path)"
                  >
                    {{ item.title }}
                  </button>
                </div>
                <div class="w-100 mt-4">
                  <div v-if="type === '/profile' || type === ''">
                    <div class="card-body">
                      <div class="profile-inner">
                        <div class="profile-img-wrapper">
                          <div class="pb-3">
                            <h4 class="pb-3 font-weight-bold">
                              {{ profile.name }}
                            </h4>
                            <div class="avatar-upload">
                              <div class="avatar-edit">
                                <input
                                  type="file"
                                  id="imageUpload"
                                  accept=".png, .jpg, .jpeg"
                                  @change="onFileChange"
                                />
                                <label for="imageUpload">
                                  <i class="fa fa-pen"></i>
                                </label>
                              </div>
                              <div class="avatar-preview">
                                <div
                                  id="imagePreview"
                                  style="background-image: url(public/icontest.png);"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="edit-profile-wrapper">
                          <form>
                            <div class="form-group">
                              <label class="alignText">Name</label>
                              <input
                                type="text"
                                v-model="profile.name"
                                placeholder="Name"
                                class="form-control form-control-solid"
                              />
                            </div>
                            <div class="form-group">
                              <label class="alignText">Email</label>
                              <input
                                type="email"
                                v-model="profile.email"
                                placeholder="Email"
                                class="form-control form-control-solid"
                              />
                            </div>
                            <div class="form-group">
                              <label class="alignText">Contact</label>
                              <input
                                type="text"
                                v-model="profile.mobile_number"
                                placeholder="Contact"
                                class="form-control form-control-solid"
                              />
                            </div>
                            <div class="form-group" v-if="isPhoneVerified">
                              <label class="alignText" style="max-width: 100%"
                                >Enter Two Factor Authentication Code</label
                              >
                              <input
                                type="number"
                                v-model="code"
                                placeholder="Enter Code"
                                class="form-control form-control-solid"
                              />
                            </div>
                            <div
                              v-if="isPhoneVerified"
                              class="form-group d-flex flex-wrap justify-content-between align-items-center"
                            >
                              <button
                                type="button"
                                ref="kt_verify_otp"
                                class="btn btn-primary my-3 font-size-3"
                                @click="verifyOtp"
                              >
                                Submit Two Factor Authentication
                              </button>
                              <button
                                type="button"
                                class="btn btn-primary my-3 font-size-3"
                                @click="sendOtp"
                              >
                                Resend Two Factor Authentication
                              </button>
                            </div>
                            <div
                              class="text-right pt-4 mt-5 submit-btn-wrapper"
                            >
                              <button
                                v-if="permission.is_edit == 1"
                                @click="updateProfile"
                                type="button"
                                class="btn custom-btn-style"
                              >
                                Save
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="type === '/change_password'">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="text-left">Old password</label>
                          <input
                            type="password"
                            required
                            v-model="oldPassword"
                            placeholder="Enter old password"
                            class="form-control form-control-solid"
                          />
                        </div>
                        <div class="form-group">
                          <label class="text-left">New Password</label>
                          <input
                            type="password"
                            required
                            v-model="newPassword"
                            placeholder="Enter new password"
                            class="form-control form-control-solid"
                          />
                        </div>
                        <div class="form-group">
                          <label class="text-left">Confirm Password</label>
                          <input
                            type="password"
                            required
                            v-model="confirmPassword"
                            placeholder="Enter confirm password"
                            class="form-control form-control-solid"
                          />
                        </div>
                        <div class="text-right pt-4 mt-5 submit-btn-wrapper">
                          <button
                            v-if="permission.is_edit == 1"
                            @click="changePassword"
                            type="button"
                            class="btn custom-btn-style"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapState } from "vuex";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import KTCodePreview from "@/view/content/CodePreview.vue";
import axios from "axios";
import firebase from "firebase";
import helpers from "../../helpers";

export default {
  name: "profile",
  components: {
    KTCodePreview
  },
  data: function() {
    return {
      showCodeField: false,
      loader: false,
      type: "",
      menu: [
        {
          id: 1,
          path: "/profile",
          title: "Profile",
          class: "btn btn--menu active"
        },
        {
          id: 2,
          path: "/change_password",
          title: "Change Password",
          class: "btn btn--menu"
        }
      ],
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      user: {},
      profile: {
        user_uuid: "",
        name: "",
        email: "",
        mobile_number: "",
        image: null,
        url: ""
      },
      appVerifier: "",
      code: "",
      isPhoneVerified: false,
      operationType: 0
    };
  },
  methods: {
    updateClass(id, path) {
      for (let i = 0; i < this.menu.length; i++) {
        this.menu[i].class = "btn btn--menu";
        if (this.menu[i].id === id) {
          this.menu[i].class = "btn btn--menu active";
          this.type = path;
        }
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.profile.image = file;
      this.profile.url = URL.createObjectURL(file);
      $("#imagePreview").css(
        "background-image",
        "url(" + this.profile.url + ")"
      );
    },
    getProfile() {
      this.user = JSON.parse(localStorage.getItem("currentUser"));
      this.profile = {
        user_uuid: this.user.uuid,
        name: this.user.name,
        email: this.user.email,
        mobile_number: this.user.mobile_number,
        url: this.user.avatar_src
      };
      $("#imagePreview").css(
        "background-image",
        "url(" + this.profile.url + ")"
      );
    },
    initReCaptcha() {
      setTimeout(() => {
        // let vm = this
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function() {},
            "expired-callback": function() {}
          }
        );
        this.appVerifier = window.recaptchaVerifier;
      }, 1000);
    },
    sendOtp() {
      let vm = this;
      let phoneNumber = vm.profile.mobile_number;
      let appVerifier = vm.appVerifier;
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(function(confirmationResult) {
          vm.isPhoneVerified = true;
          vm.resendOtp = true;
          vm.$bvToast.toast(
            "Two Factor Authentication code send your phone number please verify!",
            {
              title: "Success",
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center"
            }
          );
          window.confirmationResult = confirmationResult;
          vm.loader = false;
        })
        .catch(function(error) {
          vm.loader = false;
          vm.errorMessage(error.message);
        });
    },
    verifyOtp() {
      const submitButton = this.$refs["kt_verify_otp"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      let code = this.code;
      let vm = this;
      window.confirmationResult
        .confirm(code)
        .then(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          vm.$bvToast.toast(
            "Code Verified. Please save to update phone number!",
            {
              title: "Success",
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center"
            }
          );
          this.operationType = 1;
          this.isPhoneVerified = false;
        })
        .catch(function(error) {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          vm.errorMessage(error.message);
        });
    },
    updateProfile() {
      this.loader = true;
      let formData = new FormData();
      formData.append("name", this.profile.name);
      formData.append("email", this.profile.email);
      formData.append("mobile_number", this.profile.mobile_number);
      formData.append("avatar_src", this.profile.image);

      if (this.operationType === 0) {
        if (this.profile.mobile_number !== this.user.mobile_number) {
          this.showCodeField = true;
          this.sendOtp();
          return false;
        }
      }

      ApiService.post(
        this.$path + "/admin/user/" + this.profile.user_uuid,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
        .then(data => {
          localStorage.setItem("currentUser", JSON.stringify(data.data.data));
          let userDetail = JSON.parse(localStorage.getItem("currentUser"));

          this.$store.dispatch(UPDATE_USER, userDetail);

          this.$bvToast.toast("Profile Updated Successfully!", {
            title: "Success",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center"
          });
          this.profile = {
            name: data.data.name,
            email: data.data.email,
            mobile_number: data.data.mobile_number,
            url: data.data.avatar_src
          };
          this.getProfile();
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    changePassword() {
      let message;
      if (this.oldPassword.length === 0) {
        message = "please enter the old password";
        this.errorMessage(message);
        return;
      }
      if (this.newPassword.length === 0) {
        message = "please enter the new password";
        this.errorMessage(message);
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        message = "The confirm password does not match";
        this.errorMessage(message);
        return;
      }
      let data = {
        old_password: this.oldPassword,
        password: this.newPassword
      };
      this.loader = true;
      axios
        .patch(this.$path + "/admin/user/password/change/", data)
        .then(() => {
          this.$bvToast.toast("Password updated successfully!", {
            title: "Success",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center"
          });
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            this.errorOther(err.response.data);
          }
          if (err.response.data.status === 422) {
            this.validationError(err.response.data.errors);
          }
          if (err.response.status === 500) {
            this.serverError();
          }
        });
    },
    errorOther(error) {
      this.$bvToast.toast(error.message, {
        title: "Error",
        variant: "danger",
        solid: true,
        toaster: "b-toaster-top-center"
      });
    },
    errorMessage(error) {
      this.$bvToast.toast(error, {
        title: "Error",
        variant: "danger",
        solid: true,
        toaster: "b-toaster-top-center"
      });
    },
    validationError(error) {
      this.$bvToast.toast(error[Object.keys(error)[0]][0], {
        title: "Error",
        variant: "danger",
        solid: true,
        toaster: "b-toaster-top-center"
      });
    },
    serverError() {
      this.$bvToast.toast("Server Error", {
        title: "Error",
        variant: "danger",
        solid: true,
        toaster: "b-toaster-top-center"
      });
    }
  },
  created() {
    this.initReCaptcha();
  },
  mounted() {
    this.getProfile();
    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
          $("#imagePreview").css(
            "background-image",
            "url(" + e.target.result + ")"
          );
          $("#imagePreview").hide();
          $("#imagePreview").fadeIn(650);
        };
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imageUpload").change(function() {
      readURL(this);
    });
    let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    this.permission = {
      is_add: permission.is_add,
      is_deleted: permission.is_deleted,
      is_edit: permission.is_edit,
      web_access_enabled: permission.web_access_enabled
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>

<style scoped>
.alignText {
  text-align: left !important;
}
</style>
